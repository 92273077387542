<template>
    <div class="my_concern_container">
        <!-- 有数据时 -->
        <ul class="my_concern_list" v-if="hasConcern == true">
            <li class="my_concern_li clearfix" v-for="(item, index) in concernList" :key="index" @click="enrollEvent(item)">
                <div class="concern_message f_left">
                    <!-- <span class="message_img" :style="{backgroundImage: 'url(' + item.images + ')'}"></span> -->
                    <img :src="item.images" class="message_img" alt="">
                    <div class="message_detail">
                        <p class="title">{{item.title}}</p>
                        <p class="remain_time">{{item.timeText}}</p>
                        <p class="match_time">竞赛时间：{{item.matchStartDate | formatTime}}</p>
                    </div>
                </div>
                <div class="button_box f_right">
                    <button :class="['concern_btn', {'active': item.isAttention}]" @click.stop="removeAttention(item)">{{item.attentionText}}</button>
                    <!-- <button class="enroll_btn" @click="enrollEvent(item)">{{item.timeText}}</button> -->
                </div>
            </li>
        </ul>
        <div class="pagination_container" v-if="hasConcern == true">
            <m-pagination :currentpage="currentPage" :total="totalCount" @current-change="currentChange"></m-pagination>
        </div>
        <!-- 无数据时 -->
        <div class="no_result" v-if="hasConcern == false">
            <i class="no_result_icon"></i>
            <span class="no_result_text">这里没有任何的内容呀</span>
        </div>
    </div>
</template>
<script>
import { personalCenter, Like } from '@/config/request'
export default {
    name: 'myConcern',
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    filters: {
        formatTime(value) {
            if(value&&value.split(' ')[0]){
             value = value.split(' ')[0]
            }
            return value
        }
    },
    data(){
        return {
            accountId: '',
            currentPage: 1,
            totalCount: 0,
            concernList: [], // 关注列表数据
            timer: null, // 倒计时
            hasConcern: true, // 是否有关注数据
        }
    },
    methods: {
        // 获取数据
        getData() {
            personalCenter.getConcernData({
                // accountId: this.accountId,
                // type: 2,
                rows: this.currentPage,
                size: 10
            }).then(res => {
                if(res.code==200){
                    res.data=this.$DateFormatimg(res.data)
                this.totalCount = res.total
                res.data.forEach(item => {
                    item.isEnroll = true;
						// console.log('map',item);
						var canlder = (item.state & 0XF0);
						var expire = (item.state & 0X04);
						var cancel = (item.state & 0X01);
						console.log(item.state);
						if (canlder == 16) {
							// data.isEnroll = false;
                            this.$set(item,'isEnroll',false)
						}
						if (cancel == 1 || expire == 4) {
							if (cancel == 1) {
								// data.labels = "赛事已取消";
                                this.$set(item,'lables','赛事已取消')
							} else {
								// data.labels = "赛事已延期";
                                this.$set(item,'lables','赛事已延期')
							}
						// data.isEnroll = false;
                         this.$set(item,'isEnroll',false)
						}
                        if(!item.isEnroll){
item.timeText=item.lables
                        }else{
                            // 处理赛事状况 以及 倒计时显示
                    switch(item.status) {
                        case -1: 
                            item.timeText = '赛事已关闭'
                            break;
                        case 0: 
                            this.countDownTime((new Date(item.currentTime)).getTime()+1000, item.enrollStartDate, '距报名开始', item)
                            break;
                        case 1: 
                            this.countDownTime((new Date(item.currentTime)).getTime()+1000, item.enrollEndDate, '距报名结束', item)
                            break;
                        case 2: 
                           item.timeText = '报名已结束'
                            break;
                        case 3: 
                            this.countDownTime((new Date(item.currentTime)).getTime()+1000, item.matchStartDate, '即将开赛', item)
                            break;
                        case 4: 
                             this.countDownTime((new Date(item.currentTime)).getTime()+1000, item.matchEndDate, '距赛事结束', item)
                              break;
                            case 5: 
                            item.timeText = '赛事已完成';break;
                    }
                        }
                    
                    item.attentionText = '已关注'
                    item.isAttention = false
                })
                this.concernList = res.data
                this.hasConcern = this.concernList.length > 0 ? true : false
                }
            })
        },
        // 分页
        currentChange(page) {
            this.currentPage = page
            this.getData()
        },
        // 数字为0时，补足一个0，显示为00
        checkTime(num){
            if (num >= 10) {
                return num
            } else {
                return `0${num}`
            }
        },
        // 倒计时
        countDownTime(startTime, endTime, text, item) {
            let newStartTime = (new Date(startTime)).getTime()
            let newEndTime = (new Date(endTime)).getTime()
            let day = 0
            let hour = 0
            let minute = 0
            let second = 0
            let remaining_time = (newEndTime - newStartTime) / 1000; //计算剩余的毫秒数
            this.timer = window.setInterval(() => {
                if(remaining_time > 0) {
                    day = parseInt(remaining_time / 60 / 60 / 24)
                    hour = parseInt(remaining_time / 60 / 60 % 24)
                    minute = this.checkTime(parseInt(remaining_time / 60 % 60));
                    second = this.checkTime(parseInt(remaining_time % 60));
                } else {
                    clearInterval(this.timer)
                    day = '00';
                    hour = '00';
                    minute = '00';
                    second = '00';
                }
                this.$set(item, 'timeText', `${text}：${day}天${hour}小时${minute}分${second}秒`)
                remaining_time--;
            }, 1000)
        },
        // 取消关注
        removeAttention(item) {
            Like({
                type: 'MATCH',
                id:item.id,
            }).then(res => {
                if(res.code == 200) {
                    this.getData()
                    // if(res.data == 1) { // 取消关注
                    //     item.attentionText = '+关注'
                    //     item.isAttention = true
                    // } else if(res.objectData == 2) { // 关注
                    //     item.attentionText = '已关注'
                    //     item.isAttention = false
                    // }
                }
            })
        },
        // 立即报名
        enrollEvent(item) {
            this.$router.push({
                path: '/eventDetail',
                query: {
                    eventId: item.id
                }
            })
        }
    },
    created() {
        this.accountId = this.userInfo.id
        this.getData()
    },
    destroyed() {
        clearInterval(this.timer)
    },
}
</script>
<style scoped>
    @import './style/myConcern.css';
</style>