<template>
    <div class="my_collection_container">
        <ul class="collect_tab_ul">
            <li :class="['collect_tab_li', {'active': collectType == 6}]" @click="changeCollectType(6)">点评问大家</li>
            <li :class="['collect_tab_li', {'active': collectType == 1}]" @click="changeCollectType(1)">新闻</li>
        </ul>
        <!-- 有数据时 -->
        <ul class="collect_list_ul" v-if="hasCollect == true">
            <li class="collect_list_li" v-for="(item, index) in collectList" :key="index">
                <span
                  :class="['collect_image', {'comment_ask_image': collectType == 6}]"
                  :style="{backgroundImage: 'url(' + item.image + ')'}"
                  v-if="collectType == 1 || collectType == 6 && item.image"
                ></span>
                <div :class="['collect_detail', {'comment_ask_box': collectType == 6}]">
                    <p class="collect_detail_title text_oneLine" v-html="item.title" v-if="collectType == 6"></p>
                    <p :class="['collect_detail_text', {'text_oneLine': collectType == 6}, {'news_detail_text text_twoLine': collectType == 1}]" v-html="item.name"></p>
                    <div class="other_msg">
                        <ul class="msg_list">
                            <li class="msg_list_li time">
                                {{item.startDate}}
                            </li>
                            <li class="msg_list_li read" v-if="collectType == 1">
                                浏览：{{item.hits}}
                            </li>
                            <li class="msg_list_li location" v-if="collectType == 6 && item.location">
                                <i class="location_icon"></i>
                                <span class="location_text">{{item.location}}</span>
                            </li>
                            <li class="msg_list_li comment_ask_type" v-if="collectType == 6">
                                <span :class="['type_icon', item.type == 3 ? 'comment' : 'ask']"></span>
                            </li>
                        </ul>
                        <button class="see_detail" @click="seeDetail(item)">查看详情</button>
                    </div>
                </div>
            </li>
        </ul>
        <div class="pagination_container" v-if="hasCollect == true">
            <m-pagination :currentpage="currentPage" :total="totalCount" @current-change="currentChange"></m-pagination>
        </div>
        <!-- 无数据时 -->
        <div class="no_result" v-if="hasCollect == false">
            <i class="no_result_icon"></i>
            <span class="no_result_text">这里没有任何的内容呀</span>
        </div>
    </div>
</template>
<script>
import { personalCenter } from '@/config/request'
import { changeTime } from '@/config/utils'
export default {
    name: 'myCollection',
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            accountId: '',
            mobile: '',
            // access_token: '',
            collectType: 6, // 收藏类型
            collectList: [], // 收藏数据列表
            currentPage: 1,
            totalCount: 0,
            hasCollect: true, // 是否有收藏数据
        }
    },
    methods: {
        // 获取点评问大家列表数据
        getData() {
            personalCenter.getCollectionData({
                accountId: this.accountId,
                type: 6,
                pageNo: this.currentPage,
                pageSize: 10
            }).then(res => {
                this.totalCount = res.count
                let commentAskArr = []
                for(var k in res.objectData) {
                    res.objectData[k].forEach(item => {
                        let obj = {}
                        obj.image = item.commentImage
                        obj.name = item.type == 3 ? item.commentContent : item.questionContent
                        obj.title = item.type == 3 ? item.commentTitle : item.questionTitle
                        obj.startDate = changeTime(item.createDate, 'time')
                        obj.location = item.commentAddress
                        obj.type = item.type
                        obj.id = item.type == 3 ? item.commentId : item.questionId
                        obj.eventId = item.eventId
                        commentAskArr.push(obj)
                    })
                }
                this.collectList = commentAskArr
                this.hasCollect = this.collectList.length > 0 ? true : false
            })
        },
        // 获取新闻列表数据
        getNewsData() {
            personalCenter.getNewsCollectList({
                mobile: this.mobile,
                pageNo: this.currentPage
            }).then(res => {
                // console.log(res)
                this.totalCount = res.count
                if(res.hasOwnProperty('eventList')) {
                    this.collectList = res.eventList
                    this.hasCollect = true
                } else {
                    this.hasCollect = false
                }
                
                // this.hasCollect = this.collectList.length > 0 ? true : false
            })
        },
        // 切换收藏类型
        changeCollectType(type) {
            this.collectType = type
            this.collectType == 6 ? this.getData() : this.getNewsData()
        },
        // 切换分页
        currentChange(page) {
            this.currentPage = page
            this.collectType == 6 ? this.getData() : this.getNewsData()
        },
        // 查看详情
        seeDetail(item) {
            if(this.collectType == 1) { // 跳转至新闻详情
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        articleId: item.articleId
                    }
                })
            } else { // 跳转至点评问大家详情
                this.$router.push({
                    path: '/eventDetail',
                    query: {
                        eventId: item.eventId,
                        selectNav: item.type
                    }
                })
            }
        }
    },
    created() {
        this.accountId = this.userInfo.id
        this.mobile = this.userInfo.mobile
        this.getData()
    },
}
</script>
<style scoped>
    @import './style/myCollection.css';
</style>