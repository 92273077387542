<template>
    <div class="my_news_container">
        <div class="notice_type_box">
            <ul class="news_tab_ul">
                <li
                  :class="['news_tab_li', {'active': noticeType == item.type}]"
                  @click="changeNoticeType(item.type)"
                  v-for="(item, index) in noticeTab"
                  :key="index"
                >
                    <i :class="['read_num', {'single_num': item.msgNum < 10}]" v-show="item.noRead != 0">{{item.msgNum}}</i>{{item.type|getTypename}}
                </li>
            </ul>
            <!-- <span class="read_all" @click="readAllNotice" v-if="hasNotice == true">一键全读</span> -->
        </div>
        <!-- 有数据时 -->
        <ul class="notice_list_ul" v-if="hasNotice == true">
            <li
              class="notice_list_li"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="toDetail(item)"
            >
            <!-- <div :class="['isphoto', {'isRead': item.read == true}]"> -->
                <div>
                <img v-if="item.photo" :src="item.photo|$DateFormatimg" class="isphoto"/>
                <img v-else class="isphoto" src="@/assets/icons/heard.png" alt=""/>
                </div>
                <div class="notice_content">
                    <div>{{item.username}}</div><div class="title" >{{noticeType=='COMMENT'?item.content:item.title}}</div>
                    <div class="content" >我：{{item.commentContent}}</div>
                    <div style="width:35%;height:120px;backgroundColor:#f3f4f5;display:flex;">
                    <img :src="item.paramCover|$DateFormatimg" alt="" style="height:120px;width:120px;object-fit: cover;" mode="">
                    <div style="width:60%;line-height:120px;font-size:16px;font-weight:600;margin-left:8px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.paramTitle}}</div>
                    </div>
                    <p class="time">{{formatTime(item.createDate)}}</p>
                </div>
            </li>
        </ul>
        <div class="pagination_container" v-if="hasNotice">
            <m-pagination :currentpage="currentPage" :total="totalCount" @current-change="currentChange"></m-pagination>
        </div>
        <!-- 无数据时 -->
        <div class="no_notice" v-else>
            <i class="no_notice_icon"></i>
            <span class="no_notice_text">您当前还没有任何消息，快去看看我们的赛事吧</span>
        </div>
    </div>
</template>
<script>
import { personalCenter } from '@/config/request'
import { changeTime,getDate2 } from '@/config/utils'
export default {
    name: 'myNews',
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            accountId: '', // 用户id
            access_token: '',
            noticeType: 1, // 消息类型
            noticeTab: [
                // {name: '赛事', noticeType: 1},
                // {name: '新闻', noticeType: 3},
                // {name: '通知', noticeType: 8},
            ],
            noticeList: [], // 消息数据
            currentPage: 1, // 当前页
            totalCount: 0, // 总条数
            eventNum: '', // 赛事未读数量
            newsNum: '', // 新闻未读数量
            systemNum: '', // 通知未读数量
            hasNotice: true, // 是否有消息
            loginTime:''
        }
    },
    filters:{
getTypename(item){
   
                         switch (item) {
                        case 'SYS_NOTIFY':
                           return item='系统消息'
                            break;
                        case 'PRAISE':
                           return item='点赞消息'
                            break;
                        case 'COMMENT':
                           return item='评论消息'
                            break;
                        case 'MATCH_REMIND':
                           return item='赛事提醒'
                            break;
                        case 'PUSH_NOTIFY':
                           return item='推送通知'
                            break;
                        default:
                            break;
                    }
                  
}
    },
    methods: {
        // 获取数据
        getData() {
            personalCenter.getNoticeData({
                // accountId: this.accountId,
                type: this.noticeType,
                rows: this.currentPage,
                size: 10,
                // access_token: sessionStorage.getItem('access_token')
            }).then(res => {
                console.log(res);
                this.noticeList = res.data
                this.totalCount = res.total
                if(this.noticeList.length > 0) {
                    this.hasNotice = true
                } else {
                    this.hasNotice = false
                }
                console.log(this.hasNotice);
            })
        },
        getTab(){
            this.loginTime=sessionStorage.getItem('loginTime')
            console.log(this.loginTime);
            personalCenter.getTabData({
                // start_time:getDate2(new Date().getTime())
                // start_time:this.loginTime
                start_time:'2023-01-01 12:00:00'
            }).then(res => {
                console.log(res);
                if(res.code==200) {
                    if(res.data.length){
                        this.noticeType=res.data[0].type
                    this.noticeTab=res.data
                    this.getData()
                    }else{
                        this.hasNotice=false
                    }
                   
                }
                // this.noticeList = res.list
                // this.totalCount = res.count
                // if(this.noticeList.length > 0) {
                //     this.hasNotice = true
                // } else {
                //     this.hasNotice = false
                // }
            })
        },
        // 获取消息未读数量
        getNoticeQuantity() {
            personalCenter.getNoticeQuantity({
                accountId: this.accountId,
            }).then(res => {
                this.noticeTab.forEach(item => {
                    switch (item.noticeType) {
                        case 1:
                            this.$set(item, 'unReadNum', res.objectData.match_message)
                            break;
                        case 3:
                            this.$set(item, 'unReadNum', res.objectData.news_message)
                            break;
                        case 8:
                            this.$set(item, 'unReadNum', res.objectData.system_message)
                            break;
                        default:
                            break;
                    }
                })
            })
        },
        // 数据转换 时间戳转为时间格式
        formatTime(time) {
            return changeTime(time, 'time')
        },
        // 切换消息类型
        changeNoticeType(type) {
            this.noticeType = type
            this.currentPage = 1
            this.getData()
        },
        // 查看消息详情  赛事 和 新闻
        toDetail(item) {
            // this.readSingleNotice(item)
            if(item.type == 'MATCH_COMMENT'||item.type == 'MATCH_COMMENT_PRAISE'||item.type=='MATCH_REMIND') { // 赛事
                this.$router.push({
                    path: '/eventDetail',
                    query: {
                        eventId: item.paramId
                    }
                })
            } else if(item.type == 'NEWS_COMMENT'||item.type == 'NEWS_COMMENT_PRAISE'){ // 新闻
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        articleId: item.paramId
                    }
                })
            }
            // else if(item.type == 'OUTLINE'){ // 外链
            //     // this.$router.push({
            //     //     path: '/newsDetail',
            //     //     query: {
            //     //         articleId: item.id
            //     //     }
            //     // })
            //     window.location.href=item.paramId
            // } else if(this.noticeType == 8) {
            //     // this.getNoticeQuantity()
            //     this.getData()
            // }
        },
        // 分页
        currentChange(page) {
            this.currentPage = page
            this.getData()
        },
        // 单条阅读消息
        readSingleNotice(item) {
            personalCenter.readSingleNotice({
                id: item.id,         
                // accountId: this.accountId,
                type: item.type
            }).then(res => {
                item.read = true
                
            })
        },
        // 一键全读消息
        readAllNotice() {
            personalCenter.readAllNotice({
                type:'ALL',
                id:''
            }).then(res => {
                // this.getNoticeQuantity()
                this.getTab()
            })
        }
    },
    created() {
        let access_token = sessionStorage.getItem('access_token')
        if(access_token) {
            this.accountId = this.userInfo.id,
            this.getTab()
            // this.getNoticeQuantity()
            // this.getData()
        }
        
        
        
    },
}
</script>
<style scoped>
    @import './style/myNotice.css';
</style>
