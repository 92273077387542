<template>
    <div class="personal_center_box">
        <Header ref="Header"></Header>
        <div class="info_container">
            <!-- 用户信息 -->
            <div class="user_info_box">
                <div class="user_header">
                    <span class="header_image" v-if="userInfo.photo" :style="{backgroundImage: 'url(' + userInfo.photo + ')'}"></span>
                     <!-- <span :style="{backgroundImage: 'url(' +'../.././assets/icons/heard.png' + ')'}"></span> -->
                     <img  v-else class="header_image" src="../.././assets/icons/heard.png" alt="">
                </div>
                <div class="user_info">
                    <span class="name">{{userInfo.nickName}}</span>
                    <span class="sex_age">
                        <i :class="['sex_icon', userInfo.gender == 0 ? 'woman' : 'man']"></i>
                        <span v-if="userInfo.age" :class="['age', userInfo.sex == 0 ? 'woman' : 'man']">{{userInfo.birthday}}</span>
                    </span>
                </div>
                <p class="paopao_account">
                    <span class="paopao">ID:{{userInfo.runCode}}</span>
                    <span class="user_lv">
                        <i class="lv_num">{{userInfo.level}}</i>
                    </span>
                </p>
            </div>
            <!-- 消息tab栏切换 -->
            <ul class="info_tab_ul">
                <li
                  :class="['tab_li', {'active': componentName == item.component}]"
                  @click="chooseInfoType(item.type)"
                  v-for="(item, index) in infoTab"
                  :key="index"
                >
                    {{item.name}}
                </li>
            </ul>
        </div>
        <div class="tab_change_content">
            <div class="container">
                <component :is="componentName" :userInfo="userInfo"></component>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import myNotice from './myNotice/myNotice'
import myConcern from './myConcern/myConcern'
import myCollection from './myCollection/myCollection'
import myEnrollInfo from './myEnrollInfo/myEnrollInfo'
import myEnrollRecord from './myEnrollRecord/myEnrollRecord'
export default {
    name: 'personalCenter',
    components: {
        myNotice,
        myConcern,
        myCollection,
        myEnrollInfo,
        myEnrollRecord
    },
    data() {
        return  {
            userInfo: {}, // 用户信息
            access_token: '',
            componentName: 'myNotice', // 组件名称
            infoTab: [ // tab数据
                {name: '我的消息', type: 1, component: 'myNotice'},
                {name: '我的关注', type: 2, component: 'myConcern'},
                // {name: '我的收藏', type: 3, component: 'myCollection'},
                // {name: '报名人信息', type: 4, component: 'myEnrollInfo'},
                {name: '报名记录', type: 5, component: 'myEnrollRecord'},
            ],
            componentType: new Map([
                [1, ['myNotice']],
                [2, ['myConcern']],
                // [3, ['myCollection']],
                // [4, ['myEnrollInfo']],
                [5, ['myEnrollRecord']],
                ['default', ['myNotice']],
            ]),
        }
    },
    methods: {
        // 选择查看的信息类型
        chooseInfoType(type) {
            if(!this.access_token) {
                this.$refs.Header.Login()
                return
            }
            let componentName = this.componentType.get(type) || this.componentType.get('default')
            this.componentName = componentName[0]
            this.$router.push({
                path: '/personalCenter',
                query: {
                    componentName: this.componentName
                }
            })
        }
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.access_token = sessionStorage.getItem('access_token')
        this.componentName = this.$route.query.componentName ? this.$route.query.componentName : 'myNotice'
    },
    mounted() {
        if(!this.access_token) {
            this.$refs.Header.Login()
        }
    },
}
</script>

<style>
@import './style/personalCenter.css';
</style>
