<template>
    <div class="enroll_info_container">
        <h2 class="title">报名人信息</h2>
        <button class="add_info_btn" @click="addInfo">
            <i class="add_icon"></i>
            新增报名人信息
        </button>
        <ul class="enroll_user_list">
            <li class="user_li" v-for="(item, index) in userList" :key="index">
                <ul class="info_operate">
                    <li class="info_operate_li">姓名：{{item.name}}</li>
                    <li class="info_operate_li">性别：{{item.sex}}</li>
                    <li class="info_operate_li">手机号：{{item.mobileNum}}</li>
                    <li class="info_operate_li">证件号：{{item.cardId}}</li>
                    <li class="info_operate_li">
                        <span class="edit" @click="editUserInfo(item)">编辑</span>
                        |
                        <span class="delete" @click="showDeleteDialog(item.id)">删除</span>
                    </li>
                </ul>
                <p class="set_default_enroll" @click="setDefaultUser(item)">
                    <i :class="['icon', {'active': item.isDefault == 1}]"></i>
                    <span class="text">{{item.isDefault == 1 ? '默认报名人' : '设置为默认报名人'}}</span>
                </p>
            </li>
        </ul>
        <div class="pagination_container">
            <m-pagination :currentpage="currentPage" :total="totalCount" @current-change="currentChange"></m-pagination>
        </div>
        <info-dialog
          :show.sync="isShowDialog"
          :formControl="formControl"
          :formRules="formRules"
          :updateFormData="updateFormData"
          @closeDialog="closeDialog"
        ></info-dialog>
        <el-dialog
          title="删除报名人信息"
          :visible.sync="deleteDialog"
          width="30%"
          center
        >
            <span style="text-align: center">您确定要删除该报名人信息吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialog = false">取 消</el-button>
                <el-button type="primary" @click="deleteUserInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { personalCenter } from '@/config/request'
import dyncForm from '@/config/mixin'
export default {
    name: 'enrollInfo',
    mixins:[dyncForm],
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            currentPage: 1,
            totalCount: 0,
            totalUserList: [], // 用户信息总数据
            userList: [], // 用户信息列表
            mobile: '',
            isShowDialog: false, // 添加 / 编辑 弹框
            deleteDialog:false, // 删除 弹框
            deleteUserId: '', // 删除的报名人id
            formList: [],
            updateFormData: {}
        }
    },
    methods: {
        // 关闭弹框
        closeDialog() {
            this.isShowDialog = false
            this.updateFormData = {}
            this.getUserListData()
        },
        // 获取用户列表
        getUserListData() {
            personalCenter.getUserList({
                mobile: this.mobile,
                entryId: '', // 赛事类型的id
            }).then(res => {
                // console.log(res)
                if(res.code == 1) {
                    this.totalUserList = res.list
                    this.userList = this.totalUserList.slice(0, 10)
                    this.totalCount = res.count
                }
            })
        },
        // 切换分页
        currentChange(page) {
            this.currentPage = page
            this.userList = this.totalUserList.slice((page - 1) * 10, page * 10)
            // this.getUserListData()
        },
        // 新增用户信息
        addInfo() {
            this.isShowDialog = true
        },
        // 获取表单控件数据
        getFormListData() {
            personalCenter.getFormData({}).then(res => {
                // console.log(res)
                this.formList = res.eventList
                this.formatFormList()
            })
        },
        // 编辑用户信息
        editUserInfo(item) {
            personalCenter.getUserInfo({
                personId: item.id,
                entryId: ''
            }).then(res => {
                if(res.code == 1) {
                    this.updateFormData = res.objectData
                    this.isShowDialog = true
                }
            })
        },
        // 设置默认报名人
        setDefaultUser(item) {
            // console.log(item)
            if(item.isDefault == 0) { // 不是默认报名人
                personalCenter.setDefaultUser({
                    mobile: this.mobile,
                    id: item.id,
                    isDefault: 1
                }).then(res => {
                    this.getUserListData()
                })
            }
        },
        // 显示删除的弹框
        showDeleteDialog(id) {
            this.deleteUserId = id
            this.deleteDialog = true
        },
        // 删除联系人
        deleteUserInfo() {
            personalCenter.deleteUser({
                personId: this.deleteUserId
            }).then(res => {
                this.deleteDialog = false
                this.getUserListData()
            })
        }
    },
    created() {
        this.mobile = this.userInfo.mobile
        this.getUserListData()
        this.getFormListData()
    },
}
</script>
<style scoped>
    @import './style/myEnrollInfo.css';
</style>
