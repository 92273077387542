import { render, staticRenderFns } from "./myConcern.vue?vue&type=template&id=4905e821&scoped=true&"
import script from "./myConcern.vue?vue&type=script&lang=js&"
export * from "./myConcern.vue?vue&type=script&lang=js&"
import style0 from "./myConcern.vue?vue&type=style&index=0&id=4905e821&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4905e821",
  null
  
)

export default component.exports