<template>
    <div class="my_enroll_record">
        <!-- 报名记录列表 -->
        <div class="enroll_record_list">
            <!-- 有数据时 -->
            <ul class="record_list_ul" v-if="hasRecord == true">
                <li class="record_list_li" v-for="(item, index) in recordList" :key="index">
                    <ul class="title_box">
                        <li class="title_li time_title">{{item.matchStartDate}}</li>
                        <li class="title_li user_title">报名人信息</li>
                        <li class="title_li enroll_title">报名状态</li>
                        <li class="title_li detail_title" >详细信息</li>
                    </ul>
                    <ul class="info_list" v-for="user in item.users.users" :key="user.id">
                        <li class="info_li event">
                            <span class="event_name">{{item.matchTitle}}</span>
                            <span class="event_status">{{item.entryTitle}}</span>
                        </li>
                        <li class="info_li user">
                            <span class="user_name">姓名：{{user.name}}</span>
                            <span class="user_phone">手机号：{{user.mobilePhone}}</span>
                            <span class="user_card">证件号：{{user.cardNo}}</span>
                        </li>
                        <li class="info_li order">
                            {{user.state}}
                        </li>
                        <li class="info_li other">
                            <div class="other_status">{{item.orderStatusName}}</div>
                            <button class="search_button"  @click.stop="searchEnroll(user,item)">报名查询</button>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="pagination_container" v-if="hasRecord == true">
                <m-pagination :currentpage="currentPage" :total="totalCount" @current-change="currentChange"></m-pagination>
            </div>
            <!-- 无数据时 -->
            <div class="no_result" v-if="hasRecord == false">
                <i class="no_result_icon"></i>
                <span class="no_result_text">您还没有报名记录呢，快去报名吧</span>
            </div>
        </div>
        <!-- 报名记录详情 -->
        <el-dialog
          title="报名信息"
          :visible.sync="showDialog"
          width="32%"
        >
            <div class="event_info_box" v-if="eventDetailData.users">
                <h2 class="event_name">{{eventDetailData.matchTitle}}</h2>
                <ul class="event_info_ul">
                    <li class="info_li">场次类型： {{eventDetailData.entryTitle}}</li>
                    <li class="info_li">创建时间： {{eventDetailData.users.createDate}}</li>
                    <li class="info_li">订单编号： {{eventDetailData.tradeNo}}</li>
                    <li class="info_li">订单金额： ¥{{(eventDetailData.users.enrollAmount*0.01).toFixed(2)}}</li>
                    <li class="info_li">抵用金额： ¥{{(eventDetailData.users.discountAmount*0.01).toFixed(2)}}</li>
                    <li class="info_li">实际支付： ¥{{(eventDetailData.users.payableAmount*0.01).toFixed(2)}}</li>
                    <li class="info_li">支付状态： {{eventDetailData.orderStatusName}}</li>
                    <li class="info_li">审核状态： {{eventDetailData.users.users[0].state}}</li>
                </ul>
            </div>
            <div class="user_info_box">
                <div class="title">
                    <h2 class="user_title">参赛者信息</h2>
                </div>
                <ul class="user_info_ul" >
                    <li class="user_info_li" v-for="(userSubItem, userSubIndex) in dynamicFormsField" :key="userSubIndex">
                        <span class="info_title">{{userSubItem.lable}}</span>
                        <span class="info_content" >{{userSubItem.value?userSubItem.value:'--'}}</span>
                        <!-- <ul class="image_list_ul clearfix" v-else>
                            <li class="image_li f_left" v-for="(imageItem, imageIndex) in userSubItem.value" :key="`${userIndex}_${userSubIndex}_${imageIndex}`" :style="{backgroundImage: 'url(' + imageItem + ')'}"></li>
                        </ul> -->
                    </li>
                </ul>
            </div>
            <div class="additional_info_box" v-if="eventDetailData.users&&eventDetailData.users.users[0].ads.length">
                <div class="additional_title">
                    <h2 class="title_text">附加项信息</h2>
                </div>
                <ul class="additional_list_ul">
                    <li class="additional_list_li" v-for="(additItem, additIndex) in eventDetailData.users.users[0].ads" :key="additIndex">
                        <span>{{additItem.adTitle}}</span>
                        <span>{{additItem.price*0.01.toFixed(2)}}元/人</span>
                    </li>
                </ul>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { personalCenter } from '@/config/request'
import { changeTime } from '@/config/utils'
export default {
    name: 'myEnrollRecord',
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            currentPage: 1,
            totalCount: 0,
            mobile: '',
            recordList: [], // 记录列表数据
            hasRecord: true, // 是否有报名记录
            // isShowDetail: false, // 是否显示详情
            entryId: '', // 赛事类型id
            persions: [], // 报名人id的数组
            eventName: '', // 赛事名称
            eventStatus: new Map([ // 赛事状态
                [0, ['报名未开始']],
                [1, ['报名进行中']],
                [2, ['即将开赛']],
                [3, ['赛事进行中']],
                [4, ['赛事已完成']],
                ['default', ['']],
            ]),
            orderStatus: new Map([ // 订单状态
                [5, ['赛事延期']],
                [0, ['待支付']],
                [1, ['订单已取消']],
                [2, ['支付成功']],
                [3, ['退款成功']],
                 [7, ['退款中']],
                [4, ['赛事结束']],
                 [6, ['赛事取消']],
                [-1, ['订单已失效']],
                ['default', ['订单已支付']],
            ]),
            check: new Map([ // 审核状态
                [0, ['审核通过']],
                [1, ['等待审核']],
                [2, ['审核通过']],
                [3, ['审核不通过']],
                [4, ['待抽签']],
                [5, ['已中签']],
                [6, ['未中签']],
                [10, ['等待资料上传']],
                ['default', ['']],
            ]),
            eventDetailData: { // 赛事详情
                // eventName: '', // 赛事名称
                // orderNo: '', // 订单编号
                // createDate: '', // 创建时间
                // entryType: '', // 场次类型
                // orderPay: '', // 订单金额
                // amountMoney: '', // 抵用金额
                // actualPay: '', // 实际支付
                // payStatus: '', // 支付状态
                // checkStatus: '' // 审核状态
            },
            detailUserList: [], // 订单详情用户列表
            dynamicFormsField: [], // 动态表单的字段
            additionalArr: [], // 附加项数据
            showDialog: false, // 显示详情弹框
        }
    },
    methods: {
        // 获取数据
        getEnrollRecordData() {
            personalCenter.getEnrollRecord({
                state: '',
                rows: this.currentPage,
                size:10
            }).then(res => {
                console.log(res);
                if(res.code == 200) {
                    this.totalCount = res.total
                    this.eventName = res.data.length > 0 ? res.data[0].eventName : ''
                    // res.data.forEach(item => {
                        

                        // 用户列表
                        res.data.forEach(subItem => {
                            subItem.users=[]
                            personalCenter.getEnrollRecorde(subItem.id).then(re=>{
                                  if(re.code==200){
                                           console.log(re);
                                           subItem.users=re.data
                                  }
                            })
                            subItem.matchStartDate = changeTime(subItem.createDate, 'time')
                        // // 处理赛事状态值的显示
                        // let eventStatus = this.eventStatus.get(item.eventStatus) || this.eventStatus.get('default')
                        // this.$set(item, 'eventStatusName', eventStatus[0])
                            // 处理订单状态值的显示
                            if(subItem.state==2&&(subItem.refundState&0XF0)>>4==1){
subItem.state=7
let orderStatus = this.orderStatus.get(subItem.state) || this.orderStatus.get('default')
                            let orderStatusName = orderStatus[0]
                            this.$set(subItem, 'orderStatusName', orderStatusName)
                            }else{
                                let orderStatus = this.orderStatus.get(subItem.state) || this.orderStatus.get('default')
                            let orderStatusName = orderStatus[0]
                            this.$set(subItem, 'orderStatusName', orderStatusName)
                            }
                            

                            // 处理审核状态值的显示
                            let check = this.check.get(subItem.check) || this.check.get('default')
                            let checkName = check[0]
                            this.$set(subItem, 'checkName', checkName)
                        })
                        
                    // })
                    this.recordList = res.data
                    console.log(this.recordList);
                    this.hasRecord = this.recordList.length > 0 ? true : false
                }
            })
        },
        getName(id){

        },
        // 切换分页
        currentChange(page) {
            this.currentPage = page
            this.getEnrollRecordData()
        },
        // 报名查询
        // searchEnroll(id) {
        //     // window.location.href = enrollSearch
        // },
        // 查看订单详情
        searchEnroll(user,item) {
            console.log(item)
            this.eventDetailData=item
            this.entryId = user.id
            // let personId = []
            // item.list.forEach(user => {
            //     personId.push(user.id)
            // })
            // this.persions = personId.join(',')
            // // 是否已付款
            // if(item.list[0].orderStatus == 0) {
            //     // 未付款跳转至付款页面
            //     this.$router.push({
            //         path: '/pay',
            //         query: {
            //             entryId: this.entryId,
            //             outTradeNo: item.outTradeNo,
            //             mobile: this.mobile
            //         }
            //     })
            // } else {
                // 跳转至详情
                this.showDialog = true
                this.getDynamicForms()
            // }
            
        },
        // 获取动态表单数据
        getDynamicForms() {
            
            personalCenter.getDynamicForms(this.entryId).then(res => {
                if(res.code == 200) {
                    let fieldArr =[]
                    // res.data.forEach(formItem => {
                    //     fieldArr.push({
                    //         fieldKey: formItem.key,
                    //         fieldName: formItem.name,
                    //         fieldType: formItem.formType
                    //     })
                    // })
                    for(let k in res.data){
                        fieldArr.push({
lable:k,
value:res.data[k]
                        })
                        


                    }
                    this.dynamicFormsField = fieldArr
                    console.log(this.dynamicFormsField);
                    // this.getDetailData(item)

                }
            })
        },
        // 获取详细信息
        getDetailData(item) {
            personalCenter.getEnrollRecordDetail({
                outTradeNo: item.outTradeNo,
                mobile: this.mobile,
                entryId: this.entryId,
                persions: this.persions,
                version: 'new'
            }).then(res => {
                // console.log(res)
                // this.isShowDetail = true
                // 详情的赛事信息
                let eventData = res.objectData
                this.eventDetailData.eventName = item.eventName // 赛事名称
                this.eventDetailData.orderNo = eventData.outTradeNo // 订单编号
                this.eventDetailData.createDate = changeTime(eventData.orderSuccessTime, 'time') // 创建时间
                this.eventDetailData.entryType = item.entry.name // 场次类型
                this.eventDetailData.orderPay = eventData.totalFee.toFixed(2) // 订单金额
                this.eventDetailData.amountMoney = eventData.couponPrice.toFixed(2) // 抵用金额
                this.eventDetailData.actualPay = eventData.actualPayment.toFixed(2) // 实际支付
                this.eventDetailData.payStatus  = item.list[0].orderStatusName // 支付状态
                this.eventDetailData.orderStatus  = item.list[0].orderStatus // 支付状态
                this.eventDetailData.checkStatus  = item.list[0].checkName // 审核状态

                // 附加项数据
                this.additionalArr = eventData.addits

                //  详情的用户列表信息
                // console.log(this.dynamicFormsField)
                let userList = []
                res.list.forEach(listItem => {
                    let singleList = []
                    this.dynamicFormsField.forEach(subItem => {
                        if(listItem.hasOwnProperty(subItem.fieldKey)) {
                            if(subItem.fieldType == 'image' || subItem.fieldType == 'file') {
                                singleList.push({
                                    name: subItem.fieldName,
                                    value: listItem[subItem.fieldKey].split(','),
                                    type: 'image'
                                })
                            } else {
                                singleList.push({
                                    name: subItem.fieldName,
                                    value: listItem[subItem.fieldKey],
                                    type: 'normal'
                                })
                            }
                            
                        }
                    })
                    userList.push(singleList)
                })
                this.detailUserList = userList

            })
        },
        // 返回记录列表
        // backToList() {
        //     this.isShowDetail = false
        // }

    },
    created() {
        this.mobile = this.userInfo.mobile
        this.getEnrollRecordData()
    },
}
</script>
<style scoped>
    @import './style/myEnrollRecord.css';
</style>